import { connect } from "react-redux";
import {
  user,
  addPermit,
  getPermit,
  getPermitAll,
  deletePermit,
  updatePermit,
  bulkUploadPermits,
  getPermitbyID,
  lotprovider,
  searchForOperator
} from "../../store/home/duck";
import PermitComponent from "./permit_component";

const PermitContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    addPermit,
    lotprovider,
    user,
    getPermit,
    deletePermit,
    updatePermit,
    bulkUploadPermits,
    getPermitbyID,
    getPermitAll,
    searchForOperator
  }
)(PermitComponent);

export default PermitContainer;
