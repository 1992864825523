import Loading from "../../utils/loading/Loading";
import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { Pagination } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { debounce, delayLoading, throttleOnce } from "../../global";
import LotDeleteModal from "../../utils/modal";
import Edit from "../../images/Dashboard-edit.svg";
import WhiteViewEdit from "../../images/ic-edit-white.svg";
import DarkViewEdit from "../../images/ic-edit-dark.svg";
import ScheduleWhite from "../../images/schedule-white.svg";
import ScheduleBlack from "../../images/schedule-black.svg";
import DarkViewDelete from "../../images/ic-delete-dark.svg";
import WhiteViewDelete from "../../images/ic-delete-white.svg";
import View from "../../images/IC-view.svg";
import DarkView from "../../images/DarkViewImg.svg";
import WhiteView from "../../images/WhiteViewImg.svg";
import searchicon from "../../images/ic-search.svg";
import Header from "../../components/common/Header/container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Plus from "../../images/Plus.svg";
import Minus from "../../images/Minus.png";
import uploadFile from "../../images/upload-file.svg";
import close from "../../images/Close.svg";
import leftArrow from "../../images/left-arrow.svg";
import CloseWhite from "../../images/closewhite.svg";
import { setAlert } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import NoResultFound from "../../utils/NoResultFound/NoResultFound";

export default function YourLotsComponent(props) {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const loadingState = useSelector(state => state?.loading);
  // {loadingState?.isLoading && <Loading />}

  const [operator, setOperator] = useState();
  const [searchKey, setSearchKey] = useState('');
  const [lotData, setLotData] = useState([]);
  const [show, setShow] = useState(false);
  const [taggingType, setTaggingType] = useState([]);
  const [permitAllowed, setPermitAllowed] = useState(true);
  const [name, setName] = useState("");
  const [dbcode, setDbcode] = useState("");
  const [dropboxDescription, setDropboxDescription] = useState("");
  const [lotRul, setLotRul] = useState([]);
  const [clrfld, setClrfld] = useState("");
  const [filename1, setFilename1] = useState("");
  const [filename2, setFilename2] = useState("");
  const [filename3, setFilename3] = useState("");
  const [address, setAddress] = useState("");
  const [isFetched, setisFetched] = useState(false);
  const [imageName1, setImageName1] = useState();
  const [imageName2, setImageName2] = useState();
  const [imageName3, setImageName3] = useState();
  const [notemptyModal, setnotemptyModal] = useState(false);
  const [providers, setProviders] = useState([]);
  const [isPaymentProviderShow, setIsPaymentProviderShow] = useState(false);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [selectedProvidersImgs, setSelectedProvidersImgs] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteLotData, setDeleteLotData] = useState({});
  const [permissions, setPermissions] = useState({});
  const [roleTitle, setRoleTitle] = useState("");
  const [isEditLot, setIsEditLot] = useState({ edit: false, lotId: null });

  const [imagePreview, setImagePreview] = useState({
    parkingLot: "",
    dropbox: "",
    signage: "",
  });

  const initialPageState = {
    total: 0,
    defaultCurrentPage: 1,
    currentPage: 1,
    pageSize: 10
  }

  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage) {
      getLotList(next?.currentPage)
    }
    return { ...prev, ...next }
  }, initialPageState)



  const handleChangeCheckbox = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      const k = taggingType.concat([value])
      setTaggingType(k);
    } else {
      setTaggingType(taggingType.filter((item) => item !== value));
    }

  };





  const handleChangeForpermit = (event) => {
    setPermitAllowed(event.target.value === '1');
  }


  useEffect(() => {
    getUserPermissions();
    // checkOperator();
    setLoading(true);
    getProviders();
    getOPeratorId();
  }, []);

  const getUserPermissions = async () => {
    const { value } = await props.user();
    if (value?.success) {
      setRoleTitle(value?.user?.role);
      setPermissions(value?.user?.roleId?.permissions);
    }
    setLoading(false);
  };

  async function getProviders() {
    try {
      const { value } = await props.getProviders();
      if (value?.success) {
        setProviders(value?.providers);
      } else {
        console.log(value?.message);
      }
    } catch (error) {
      console.log(error, "<<<error");
    }
  }

  const [ StripeConnected , setStripeConnected ] = useState()

  const getOPeratorId = async () => {
    const { value } = await props.user();
    if (value?.success) {
      setStripeConnected(value?.user?.stripeConnectAccount?.isStripeConnected)
      getLotList(1);
      setOperator(value);
    }
    setLoading(false);
  };
  


  async function getLotList(page) {
    const { value } = await props.lotprovider({ page, size: 10 });
    if (value?.success) {
      setPageState({ total: value?.total })
      setLotData(value?.lots);
    }
  };

  const handleClose = () => {
    setLotRul([]);
    setAddress("");
    setSelectedProvidersImgs([]);
    setSelectedProviders([]);
    setName("");
    setDbcode("");
    setDropboxDescription("");
    setFilename1("");
    setFilename2("");
    setFilename3("");
    setImageName1("");
    setImageName2("");
    setImageName3("");
    setIsEditLot({ edit: false, lotId: null });
    setImagePreview({ parkingLot: "", dropbox: "", signage: "" });
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const onAdd = async () => {
    setLoading(true);
    try {
      const { value } = await props.addlots(
        {
          operator: operator.user._id,
          lotRules: JSON.stringify(lotRul),
          address: address,
          paymentProviders: JSON.stringify(selectedProviders),
          lotname: name,
          dropbox: JSON.stringify({
            dropboxCode: dbcode,
            dropboxDescription,
          }),
          parkingLot: filename1,
          dropBox: filename2,
          signage: filename3,
          permitAllowed: permitAllowed,
          taggingType: [...taggingType]
        },
        isEditLot?.lotId,
        isEditLot?.edit
      );
      if (value?.success) {
        setShow(false);
        setisFetched(true);
        getOPeratorId();
        setLotRul([]);
        setAddress("");
        setSelectedProvidersImgs([]);
        setSelectedProviders([]);
        setName("");
        setDbcode("");
        setDropboxDescription("");
        setFilename1("");
        setFilename2("");
        setFilename3("");
        setImageName1("");
        setImageName2("");
        setImageName3("");
        setIsEditLot({ edit: false, lotId: null });
        delayLoading(setLoading);
      } else {
        setLoading(false);
        setnotemptyModal(true);
      }
    } catch (error) {
      setLoading(false);
    }
    // if (value1?success) {
    //   setLotData(value1?.value?.lots)
    //   getLotList();
    // }
  };

  const makeAddLotClose = () => {
    setnotemptyModal(false);
    setShow(false);
  };
  const handleKeyDown = (e) => {
    let arr = [...lotRul];
    arr.push(clrfld);
    setLotRul(arr);
    setClrfld("");
  };
  const getFilename1 = (e) => {
    setFilename1(e[0]);
    setImageName1(e[0].name);
  };
  const getFilename2 = (e) => {
    setFilename2(e[0]);
    setImageName2(e[0].name);
  };
  const getFilename3 = (e) => {
    setFilename3(e[0]);
    setImageName3(e[0].name);
  };
  useEffect(() => {
    getOPeratorId();
  }, [isFetched]);

  const handleProvider = (provider) => {
    const isAlreadySelected = selectedProviders?.indexOf(provider?._id);
    const isAlreadySelectedLogo = selectedProvidersImgs?.indexOf(
      provider?.logoUrl
    );
    if (isAlreadySelected === -1 && isAlreadySelectedLogo === -1) {
      setSelectedProviders([...selectedProviders, provider?._id]);
      setSelectedProvidersImgs([...selectedProvidersImgs, provider?.logoUrl]);
    } else {
      let oldProviders = selectedProviders;
      let oldProvidersImgs = selectedProvidersImgs;
      oldProviders?.splice(isAlreadySelected, 1);
      oldProvidersImgs?.splice(isAlreadySelectedLogo, 1);
      setSelectedProviders([...oldProviders]);
      setSelectedProvidersImgs([...selectedProvidersImgs]);
    }
  };

  const removeLot = (data) => {
    const index = lotRul?.indexOf(data);
    if (index !== -1) {
      let newArr = lotRul;
      newArr?.splice(index, 1);
      setLotRul([...newArr]);
    }
  };



  const onDelete = (id) => {
    setDeleteLotData({ id });

    setShowModal(true)
  };


  const onDeleteModalSubmit = async () => {
    setLoading(true);
    setShowModal(false)
    try {
      const { value } = await props.deleteyourlot(deleteLotData?.id);
      if (value?.success) {
        getOPeratorId();
        delayLoading(setLoading);
        dispatch(setAlert("success", value?.message))
      } else {
        setLoading(false);
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      dispatch(setAlert("erro", error?.message))
    }
  };


  const handleImagePreview = (e, name) => {
    const file = e?.target?.files[0];
    console.log(file);
    if (file) {
      name === "parkingLot" &&
        setImagePreview({
          ...imagePreview,
          parkingLot: URL.createObjectURL(file),
        });
      name === "dropbox" &&
        setImagePreview({
          ...imagePreview,
          dropbox: URL.createObjectURL(file),
        });
      name === "signage" &&
        setImagePreview({
          ...imagePreview,
          signage: URL.createObjectURL(file),
        });
    }
  };

  const search = async query => {
    try {
      if (query?.length > 2) {
        const { value } = await props?.searchForOperator('lot', query)
        if (value?.success) {
          setLotData(value?.results)
          setPageState({ total: 0 })
        }
        else {
          dispatch(setAlert('error', value?.message))
        }
      }
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
  }

  const searchInputRef = useRef()

  const handleLotStatus = useCallback(
    throttleOnce(async (lotId, isActive) => {
      try {
        const { value } = await props.updateLotStatus(lotId, { isActive });
        if (value?.success) {
          getLotList(pageState?.currentPage);
        } else {
          dispatch(setAlert("error", value?.message));
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message));
      }
    }, 1000),
    [pageState?.currentPage]
  );


  return (
    <>
      {isLoading && <Loading />}
      <section className="mainWrapper">
        <Header />
        <div className="main-wrapper" id="profile-wrapper">
          <div>
            <h3 className="d-flex align-items-center">
              <img
                src={leftArrow}
                className="left-arrow"
                onClick={() => navigate(-1)}
              />
              Your Lots
            </h3>
            <div className="row">
              <div className="search-lot-wrapper">
                <div className="d-flex w-100">
                  <div class="search-lot">
                    <button class="btn your-lot-btn" type="button">
                      <img src={searchicon} className="search-btn" />
                    </button>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      // value={searchKey}
                      ref={searchInputRef}
                      onChange={async (e) => {
                        setSearchKey(e?.target?.value);
                        e?.target?.value?.length > 2 &&
                          debounce(await search(e?.target?.value), 2000);
                        e?.target?.value?.length === 0 && getLotList(1);
                      }}
                    />
                  </div>
                  {(permissions?.Lots_add || roleTitle === "operator") &&
                    (StripeConnected ? (
                      <Link to="/addlots" className="DarkBtn">
                        Add Lot
                      </Link>
                    ) : (
                      <Link
                        to="/connectWithStripe"
                        className="DarkBtn"
                        onClick={() =>
                          dispatch(
                            setAlert(
                              "info",
                              "Please connect with stripe to add a lot!"
                            )
                          )
                        }
                      >
                        Add Lot
                      </Link>
                    ))}
                </div>
                <Modal show={show} onHide={handleClose} className="add-lots">
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {isEditLot?.edit ? "Edit" : "Add"} Lot
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form className="row">
                      <Form.Group
                        className="mb-3 col-lg-6 col-sm-6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Lot Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Lot name"
                          // required
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 col-lg-6 col-sm-6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          placeholder="Address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-4 col-lg-6 col-sm-6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Drop Box Details</Form.Label>
                        <Form.Control
                          placeholder="Drop Box Code"
                          value={dbcode}
                          className="mb-3"
                          onChange={(e) => setDbcode(e.target.value)}
                        />
                        <textarea
                          placeholder="Describe the location of your drop box"
                          value={dropboxDescription}
                          className="textarea-style mb-3"
                          onChange={(e) =>
                            setDropboxDescription(e.target.value)
                          }
                        />

                        <div className=" d-flex align-items-center justify-content-between vendor-block mb-4">
                          <label>Select Payment Vendors:</label>
                          <div className="payment-vendor">
                            <button
                              id="toggleProvider"
                              type="button"
                              className="paymentVendorBtn"
                              onClick={() => {
                                setIsPaymentProviderShow(true);
                                document
                                  .getElementById("toggleProvider")
                                  ?.classList?.toggle("addVendore");
                              }}
                            >
                              <div className="add-vendor">
                                {selectedProvidersImgs?.length > 0 &&
                                  selectedProvidersImgs?.map((e, index) => {
                                    if (index < 3) {
                                      return (
                                        <img
                                          src={e}
                                          alt="payment provider"
                                          key={index}
                                        />
                                      );
                                    }
                                  })}
                                {selectedProvidersImgs?.length > 3 && (
                                  <span>
                                    +{selectedProvidersImgs?.length - 3}
                                  </span>
                                )}
                              </div>
                              <span className="dropdown-arrow"></span>
                            </button>
                            {isPaymentProviderShow && (
                              <ul className="paymentVendorlist">
                                <img
                                  src={close}
                                  onClick={() => {
                                    setIsPaymentProviderShow(false);
                                    document
                                      .getElementById("toggleProvider")
                                      ?.classList?.remove("addVendore");
                                  }}
                                  className="close-btn"
                                />
                                {providers?.length > 0 &&
                                  providers?.map((provider, index) => {
                                    console.log(
                                      provider?._id,
                                      "<<<<selected providers"
                                    );
                                    return (
                                      <li
                                        key={index}
                                        onClick={() => handleProvider(provider)}
                                      >
                                        <div>
                                          <img
                                            src={provider?.logoUrl}
                                            alt={provider?.logoUrl}
                                          />
                                          {provider?.provider}
                                        </div>
                                        <div className="vendor-checkbox">
                                          <input
                                            type="checkbox"
                                            id={`weekday-mon-${index}`}
                                            class="weekday"
                                            checked={
                                              selectedProviders?.indexOf(
                                                provider?._id
                                              ) !== -1
                                            }
                                          />
                                          <label
                                            for={`weekday-mon-${index}`}
                                          ></label>
                                        </div>
                                      </li>
                                    );
                                  })}
                              </ul>
                            )}
                          </div>
                        </div>

                        <div>
                          <label>Permits Allowed</label>
                          <select
                            className="form-select"
                            aria-label="Default example"
                            value={permitAllowed ? "1" : "2"}
                            onChange={handleChangeForpermit}
                          >
                            <option value="1">Yes</option>
                            <option value="2">No</option>
                          </select>
                          {/* <p>Permit Allowed: {permitAllowed ? 'True' : 'False'}</p> */}
                        </div>

                        <div>
                          {/* {JSON.stringify(taggingType)} */}
                          <label>
                            <input
                              type="checkbox"
                              value="mailIn"
                              onChange={handleChangeCheckbox}
                            />
                            Mail-in
                          </label>
                          <br />
                          <label>
                            <input
                              type="checkbox"
                              value="tagVehicle"
                              onChange={handleChangeCheckbox}
                            />
                            Tag Vehicleeeeee
                          </label>
                          <br />
                          {/* <p>Selected values: {taggingType.join(', ')}</p> */}
                        </div>
                      </Form.Group>
                      <Form.Group
                        className="mb-3 col-lg-6 col-sm-6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Lots Rules</Form.Label>
                        <div class="input-group control-group after-add-more mb-3">
                          <div className="add-lots-feild">
                            <Form.Control
                              type="text"
                              placeholder="Enter Rules"
                              value={clrfld}
                              onChange={(e) => setClrfld(e.target.value)}
                            />
                          </div>
                          <div class="input-group-btn mb-3">
                            <button
                              class="btn  add-more"
                              type="button"
                              onClick={(e) => handleKeyDown(e)}
                            >
                              <img src={Plus} alt="Plus icon" />
                            </button>
                          </div>
                        </div>
                        <div className="add-field">
                          {lotRul.length > 0 &&
                            lotRul.map((data, index) => {
                              return (
                                <p key={index}>
                                  {data}
                                  <span onClick={() => removeLot(data)}>
                                    <img src={Minus} alt="minus icon" />
                                  </span>
                                </p>
                              );
                            })}
                        </div>
                      </Form.Group>
                      <Form.Group
                        className="col-12"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <div>
                          <label className="mb-3 form-label">
                            Upload Images
                          </label>
                          <div className="row">
                            <div className="mb-4 col-lg-4 col-sm-4 posirel">
                              {isEditLot?.edit && filename1 ? (
                                <div>
                                  <img
                                    src={CloseWhite}
                                    alt="X"
                                    onClick={() => {
                                      setImageName1("");
                                      setFilename1("");
                                    }}
                                    className="CloseBtn"
                                  />
                                  <img
                                    src={
                                      imagePreview?.parkingLot
                                        ? imagePreview?.parkingLot
                                        : filename1
                                    }
                                    alt="Parking lot"
                                    className="uploaded-img"
                                  />
                                </div>
                              ) : (
                                <div className="file-upload btn btn-lg">
                                  <img src={uploadFile} alt="" />
                                  {imageName1 ? (
                                    <label>{imageName1}</label>
                                  ) : (
                                    <div>Parking Lot</div>
                                  )}

                                  <input
                                    type="file"
                                    name="file"
                                    onChange={(e) => {
                                      handleImagePreview(e, "parkingLot");
                                      getFilename1(e.target.files);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="mb-4 col-lg-4 col-sm-4 posirel">
                              {isEditLot?.edit && filename2 ? (
                                <div>
                                  <img
                                    src={CloseWhite}
                                    alt="X"
                                    onClick={() => {
                                      setImageName2("");
                                      setFilename2("");
                                    }}
                                    className="CloseBtn"
                                  />
                                  <img
                                    src={
                                      imagePreview?.dropbox
                                        ? imagePreview?.dropbox
                                        : filename2
                                    }
                                    alt="Drop box"
                                    className="uploaded-img"
                                  />
                                </div>
                              ) : (
                                <div className="file-upload btn btn-lg">
                                  <img src={uploadFile} />
                                  {imageName2 ? (
                                    <label>{imageName2}</label>
                                  ) : (
                                    <div>Drop Box</div>
                                  )}
                                  <input
                                    type="file"
                                    name="file"
                                    onChange={(e) => {
                                      handleImagePreview(e, "dropbox");
                                      getFilename2(e.target.files);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="mb-4 col-lg-4 col-sm-4 posirel">
                              {isEditLot?.edit && filename3 ? (
                                <div>
                                  <img
                                    src={CloseWhite}
                                    alt="X"
                                    onClick={() => {
                                      setImageName3("");
                                      setFilename3("");
                                    }}
                                    className="CloseBtn"
                                  />
                                  <img
                                    src={
                                      imagePreview?.signage
                                        ? imagePreview?.signage
                                        : filename3
                                    }
                                    alt="Signage"
                                    className="uploaded-img"
                                  />
                                </div>
                              ) : (
                                <div className="file-upload btn btn-lg">
                                  <img src={uploadFile} />
                                  {imageName3 ? (
                                    <label>{imageName3}</label>
                                  ) : (
                                    <div>Signage</div>
                                  )}
                                  <input
                                    type="file"
                                    name="file"
                                    onChange={(e) => {
                                      handleImagePreview(e, "signage");
                                      getFilename3(e.target.files);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group
                        className="mb-3 col-12"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <button
                          type="button"
                          className="btn DarkBtn"
                          onClick={(e) => onAdd()}
                        >
                          {isEditLot?.edit ? "Save" : "Add"}
                        </button>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
          {
            <div className="lotsWrapper">
              <div className="table-responsive">
                <table class="table StripTable">
                  <thead>
                    <tr>
                      <th>Lot</th>
                      <th>Address</th>
                      <th>Drop Box Code</th>
                      <th>Permits Allowed</th>
                      <th>Enforcement Options</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      lotData?.length > 0 ? (
                        lotData?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{data.lotname}</td>
                              <td>{data.address}</td>
                              <td>{data?.dropbox?.dropboxCode}</td>
                              <td>{data?.permitAllowed ? "Yes" : "No"}</td>
                              <td>
                                {data?.taggingType?.sort()?.map((type, i) => {
                                  const typeMapping = {
                                    mailIn: "Mail-In",
                                    tagVehicle: "Tag Vehicle",
                                    enforcement_lite: "Enforcement Lite"
                                  };
                                  const value = `${typeMapping[type] || "Unknown"}${
                                    data?.taggingType?.length - 1 !== i ? " | " : " "
                                  }`;
                                  return value;
                                })}
                              </td>
                              <td>
                                <div
                                  className={
                                    data?.isActive
                                      ? "green LabelStatus"
                                      : "red LabelStatus"
                                  }
                                >
                                  {data?.isActive ? "Active" : "Inactive"}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <button
                                    className="ActionsButtons"
                                    onClick={() =>
                                      navigate(`/lot/details/${data?._id}`)
                                    }
                                  >
                                    <img src={DarkView} className="DarkImg" />
                                    <img src={WhiteView} className="WhiteImg" />
                                  </button>
                                  {(permissions?.Lots_update ||
                                    roleTitle === "operator") && (
                                    <button
                                      className="ActionsButtons"
                                      onClick={() =>
                                        navigate(`/lot/edit/${data?._id}`)
                                      }
                                    >
                                      <img
                                        src={DarkViewEdit}
                                        className="DarkImg"
                                        alt="Edit"
                                      />
                                      <img
                                        src={WhiteViewEdit}
                                        className="WhiteImg"
                                        alt="Edit"
                                      />
                                    </button>
                                  )}
                                  {(permissions?.Lots_add ||
                                    roleTitle === "operator") && (
                                    <button
                                      className="ActionsButtons"
                                      onClick={() =>
                                        navigate(`/lot/geofence/${data?._id}`)
                                      }
                                    >
                                     {data?.geofence?._id ? "✔️📍" : "📍"}
                                    </button>
                                  )}
                                  {(permissions?.Lots_update ||
                                    roleTitle === "operator") && (
                                      <button
                                      className="ActionsButtons"
                                      onClick={() =>
                                        navigate(`/lot/schedule/${data?._id}`)
                                      }
                                      >
                                        {data?.schedule?.length > 0 && '✔️'}
                                      <img
                                        src={ScheduleWhite}
                                        className="WhiteImg"
                                        alt="🕘"
                                      />
                                      <img
                                        src={ScheduleBlack}
                                        className="DarkImg"
                                        alt="🕘"
                                      />
                                    </button>
                                  )}
                                  {(permissions?.Lots_delete ||
                                    roleTitle === "operator") && (
                                    <button
                                      className="ActionsButtons"
                                      onClick={() =>
                                        onDelete(data?._id, data?.lotname)
                                      }
                                    >
                                      <img
                                        src={DarkViewDelete}
                                        className="DarkImg"
                                        alt="Delete"
                                      />
                                      <img
                                        src={WhiteViewDelete}
                                        className="WhiteImg"
                                        alt="Delete"
                                      />
                                    </button>
                                  )}

                                  {(permissions?.Lots_active_deactive ||
                                    roleTitle === "operator") && (
                                    <button
                                      title="Toggle to activate or deactivate"
                                      className="your-lots-btn form-check form-switch"
                                      onClick={() => {
                                        handleLotStatus(
                                          data?._id,
                                          !data?.isActive
                                        );
                                      }}
                                    >
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          id="togBtn"
                                          checked={data?.isActive}
                                        />
                                        <div className="slider round"></div>
                                      </label>
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : lotData === null || lotData?.length === 0 ? (
                        searchKey && (
                          <div className="dataText">
                            <h5>No Result Found.</h5>
                          </div>
                        )
                      ) : (
                        <div className="dataText">
                          <h5>No Result Found.</h5>
                        </div>
                      )
                      // (
                      //  searchKey && <h5>No results found.</h5>
                      // )
                      // (
                      //   !loadingState?.isLoading && searchKey && <h5>No results found.</h5>
                      // )
                    }
                  </tbody>
                </table>
              </div>
              <div className="pagination-block">
                <Pagination
                  hideOnSinglePage
                  defaultCurrent={pageState?.defaultCurrentPage}
                  onChange={(page) => setPageState({ currentPage: page })}
                  current={pageState?.currentPage}
                  pageSize={pageState?.pageSize}
                  total={pageState?.total}
                  showSizeChanger={false}
                />
              </div>
            </div>
          }
        </div>
      </section>
      <LotDeleteModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={onDeleteModalSubmit}
        title={"Your Lot"}
      />
    </>
  );
}
