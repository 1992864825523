import React, { useEffect, useReducer, useRef, useState } from "react";
import { Pagination } from "antd";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import Edit from "../../images/Dashboard-edit.svg";
import WhiteViewEdit from "../../images/ic-edit-white.svg";
import DarkViewEdit from "../../images/ic-edit-dark.svg";
import Delete from "../../images/ic-delete.svg";
import DarkViewDelete from "../../images/ic-delete-dark.svg";
import WhiteViewDelete from "../../images/ic-delete-white.svg";
import View from "../../images/IC-view.svg";
import DarkView from "../../images/DarkViewImg.svg";
import WhiteView from "../../images/WhiteViewImg.svg";
import searchicon from "../../images/ic-search.svg";
import PermitDeleteModal from "../../utils/modal";
import Header from "../../components/common/Header/container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import close from "../../images/Close.svg";
import leftArrow from "../../images/left-arrow.svg";
import Spinner from "../../utils/spinner";
import Loading from "../../utils/loading/Loading";
import { setAlert } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Download from "../../components/svgIcons/Download";
import { debounce, returnShotLotName } from "../../global";
import NoResultFound from "../../utils/NoResultFound/NoResultFound";
import Upload from "../../components/svgIcons/Upload";

export default function PermitComponent(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tz = moment.tz.guess();

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [error, setError] = useState(false);
  const [editPermit, setEditPermit] = useState(null);
  const [isPaymentProviderShow, setIsPaymentProviderShow] = useState(false);
  const [show, setShow] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [roleTitle, setRoleTitle] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const searchInputRef = useRef();
  const csvFileInputRef = useRef();

  const initialPageState = {
    permits: [],
    total: 0,
    defaultCurrentPage: 1,
    currentPage: 1,
    pageSize: 10,
    isLotModalShow: false,
    lots: [],
    selectedLotsName: [],
    isModalLoading: false,
    allPermits: [],
  };
  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage) {
      fetchPermit(next?.currentPage);
    }
    return { ...prev, ...next };
  }, initialPageState);
  const initialState = {
    licensePlate: "",
    permitType: "",
    // membershipEndDate: new Date(),
    membershipEndDate: null,
    lotId: [],
  };
  const [state, updateState] = useReducer((prev, next) => {
    return { ...prev, ...next };
  }, initialState);

  const clearerr = () => {
    setError(false);
  };

  const Submit = async (e) => {
    e.preventDefault();
    if (
      state?.licensePlate?.trim() == "" ||
      state?.permitType?.trim() == "" ||
      state?.lotId?.length == ""
    ) {
      setError(true);
      return;
    }

    console.log(
      state?.membershipEndDate
        ? new Date(state?.membershipEndDate)?.toISOString()
        : moment().add(50, "years").toISOString(),
      "<<<this"
    );

    const permitPayload = {
      ...state,
      membershipEndDate: state?.membershipEndDate
        ? new Date(state?.membershipEndDate)?.toISOString()
        : moment().add(50, "years").toISOString(),
    };
    // const permitPayload = { ...state }
    if (editPermit) {
      setIsLoading(true);
      try {
        const { value } = await props.updatePermit({
          id: editPermit,
          data: permitPayload,
        });
        if (value?.success) {
          await fetchPermit(1);
          setShow(false);
          updateState(initialState);
          setPageState(initialPageState);
          dispatch(setAlert("success", value?.message));
          setIsLoading(true);
        } else {
          dispatch(setAlert("error", value?.message));
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message));
      }
      setIsLoading(false);
    } else {
      try {
        if (
          state.licensePlate.trim() == "" ||
          state.permitType.trim() == "" ||
          state.lotId.length == ""
        ) {
          setError(true);
        }

        const { value } = await props.addPermit({
          ...state,
          membershipEndDate: state?.membershipEndDate
            ? new Date(state?.membershipEndDate)?.toISOString()
            : moment().add(50, "years").toISOString(),
        });
        // const { value } = await props.addPermit({ ...state })
        setIsLoading(true);
        if (value?.success) {
          setShow(false);
          updateState({
            licensePlate: "",
            permitType: "",
            // membershipEndDate: new Date(),
            membershipEndDate: "",
            lotId: [],
          });
          setPageState({ selectedLotsName: [] });
          await fetchPermit(1);
          dispatch(setAlert("success", value?.message));
        } else {
          console.log(value?.message);
          dispatch(setAlert("error", value?.message));
          // alert(value?.message)
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message));
      }
      setIsLoading(false);
    }
  };

  async function fetchPermit(page) {
    try {
      const { value } = await props.getPermit(page);
      if (value?.success) {
        // dispatch(setAlert("success", value?.message))
        console.log(value, "-------------------????????");
        setPageState({
          total: value?.total,
          permits: value?.permits,
        });
      } else {
        // alert(value?.message)
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("success", error?.message));
    }
  }

  async function fetchPermit1(isReport) {
    setIsLoading(true);
    try {
      const { value } = await props.getPermitAll(isReport);
      if (value?.success) {
        console.log(value, "-----new------->");
        setPageState({
          allPermits: value?.permits,
        });
        console.log(setPageState.allPermits, "------>all data");
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    setIsLoading(false);
  }

  // async function fetchAllPermits() {
  //   setIsLoading(true)
  //   try {
  //     const { value } = await props.getPermit()
  //     if (value?.success) {
  //       console.log(value)
  //       setAllPermits(value?.permits)
  //     }
  //     else {
  //       alert(value?.message)
  //     }
  //   } catch (error) {
  //     alert(error?.message)
  //     console.log(error?.message)
  //   }
  //   setIsLoading(false)
  // }

  const handleDeleteClick = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleEditClick = (permit) => {
    setEditPermit(permit);
    setShow(true);
    getPermitbyID(permit);
  };

  const handleConfirmDelete = async () => {
    setShowModal(false);
    try {
      const { value } = await props.deletePermit(deleteId);
      if (value?.success) {
        fetchPermit();
        fetchPermit1(true);
        dispatch(setAlert("success", value?.message));
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    setIsLoading(false);
  };

  const updatePermit = async (id, data) => {
    setIsLoading(true);
    try {
      const { value } = await props.updatePermit(id, data);
      if (value?.success) {
        fetchPermit();
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    setIsLoading(false);
  };

  const getPermitbyID = async (id) => {
    setPageState({ isModalLoading: true });
    try {
      const [permitData, lotsData] = await Promise.all([
        props.getPermitbyID(id),
        props.lotprovider({ isPermit: true }),
      ]);
      console.log(permitData?.value);
      if (permitData?.value?.success && lotsData?.value?.success) {
        const { permit } = permitData?.value;
        updateState({
          licensePlate: permit?.licensePlate || "",
          permitType: permit?.permitType || "",
          membershipEndDate: new Date(permit?.membershipEndDate),
          lotId: permit?.lotId?.map((lot) => lot?._id),
        });
        setPageState({
          lots: lotsData?.value?.lots,
          selectedLotsName: permit?.lotId,
          isModalLoading: false,
        });
      } else {
        dispatch(
          setAlert(
            "error",
            permitData?.value?.message || lotsData?.value?.message
          )
        );
        // alert(permitData?.value?.message || lotsData?.value?.message)
        console.log(permitData?.value?.message || lotsData?.value?.message);
        setPageState({ isModalLoading: false });
      }
    } catch (error) {
      setPageState({ isModalLoading: false });
      dispatch(setAlert("error", error?.message));
    }
    setIsLoading(false);
  };

  const handleAddPermitBtn = async () => {
    try {
      const { value } = await props.lotprovider({ isPermit: true });
      console.log(value);
      if (value?.success) {
        setPageState({ lots: value?.lots });
        setEditPermit(null);
        setShow(true);
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
  };

  const handleOffences = (lot) => {
    const isAlreadySelected = state?.lotId?.indexOf(lot?._id);
    // const isAlreadySelectedname = pageState?.selectedLotsName?.indexOf(lot);
    if (isAlreadySelected === -1) {
      updateState({ lotId: [...state?.lotId, lot?._id] });
    } else {
      let oldLotId = state?.lotId;
      oldLotId?.splice(isAlreadySelected, 1);
      updateState({ lotId: [...oldLotId] });
    }
    if (isAlreadySelected === -1) {
      setPageState({ selectedLotsName: [...pageState?.selectedLotsName, lot] });
    } else {
      let oldLotSelected = pageState?.selectedLotsName;
      oldLotSelected?.splice(isAlreadySelected, 1);
      setPageState({ selectedLotsName: [...oldLotSelected] });
    }
  };

  const getUserPermissions = async () => {
    const { value } = await props.user();
    if (value?.success) {
      setRoleTitle(value?.user?.role);
      setPermissions(value?.user?.roleId?.permissions);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUserPermissions();
    fetchPermit(pageState?.currentPage);
    fetchPermit1(true);
  }, []);

  const search = async (query) => {
    try {
      if (query?.length > 2) {
        const { value } = await props?.searchForOperator("permit", query);
        if (value?.success) {
          console.log(value?.results);
          setPageState({ permits: value?.results, total: 0 });
        } else {
          dispatch(setAlert("error", value?.message));
        }
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target?.files?.[0];
    if (!file) return;
    const requiredFileTypes = [
      "application/vnd.ms-excel",
      "text/csv",
      "application/csv",
    ];
    const fileType = file.type;
    const formData = new FormData();
    formData.append("file", file);

    if (!requiredFileTypes.includes(fileType))
      return alert("Please provide a csv file!");

    try {
      setIsLoading(true);

      const { value } = await props.bulkUploadPermits(formData);
      if (value?.status) {
        setIsLoading(false);
        csvFileInputRef.current.value = "";

        switch (value.status) {
          case "success":
            dispatch(setAlert("success", "File uploaded successfully!"));
            await fetchPermit(1);
            break;
          case "error":
            dispatch(setAlert("error", "Error while uploading!"));
        }
      }
    } catch (error) {
      setIsLoading(false);
      csvFileInputRef.current.value = "";
      dispatch(setAlert("error", "Error while uploading!"));
    }
  };

  return (
    <>
      {(pageState?.isModalLoading ||
        isLoading ||
        pageState?.isModalLoading) && <Loading />}
      <section className="mainWrapper">
        <Header />

        <div className="main-wrapper permit-wrapper" id="profile-wrapper">
          <div>
            <h4 className="d-flex align-items-center">
              <img
                src={leftArrow}
                className="left-arrow"
                onClick={() => navigate(-1)}
              />
              Permits
            </h4>
            <div className="row">
              <div className="search-lot-wrapper">
                <div className="permit-search">
                  <div className="search-lot">
                    <button className="btn your-lot-btn" type="button">
                      <img src={searchicon} />
                    </button>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      ref={searchInputRef}
                      onChange={async (e) => {
                        setSearchKey(e?.target?.value);
                        e?.target?.value?.length > 2 &&
                          debounce(await search(e?.target?.value), 2000);
                        e?.target?.value?.length === 0 && fetchPermit(1);
                      }}
                    />
                  </div>
                  {(permissions?.Permits_add || roleTitle === "operator") && (
                    <Button
                      variant="btn blankBtn"
                      onClick={() => {
                        handleAddPermitBtn();
                      }}
                    >
                      Add Permit
                    </Button>
                  )}
                  {(permissions?.Permits_download ||
                    roleTitle === "operator") && (
                    <>
                      {console.log(pageState?.allPermits, "<<all permits")}
                      <CSVLink
                        data={[
                          [
                            "LicensePlate",
                            "LotName",
                            "PermitType",
                            "MembershipEndDate",
                          ], // header row
                          ...pageState?.allPermits.map((p) => [
                            p.licensePlate,
                            p.lotId.map((lot) => lot.lotname).join(", "),
                            p.permitType,
                            new Date(p.membershipEndDate).toLocaleDateString(
                              "en-US"
                            ),
                          ]),
                        ]}
                        filename={"Permit.csv"}
                        className="btn DarkBtn download-btn"
                      >
                        <Download />
                        Download
                      </CSVLink>
                    </>
                  )}

                  <input
                    type="file"
                    id="csvFileInput"
                    ref={csvFileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />

                  <Button
                    id="uploadButton"
                    className="btn DarkBtn download-btn"
                    onClick={() => csvFileInputRef.current.click()}
                  >
                    <Upload />
                    Upload
                  </Button>

                  {/* <CSVLink
                    data={pageState?.allPermits.map((p) => ({
                      LicensePlate: p.licensePlate,
                      LotName: p.lotId.map(lot => lot.lotname).join(', '),
                      PermitType: p.permitType,
                      // membershipEndDate: p.membershipEndDate,
                      MembershipEndDate: new Date(p.membershipEndDate).toLocaleDateString('en-US'),
                    }))}
                    filename={"Permit.csv"}
                    className="btn DarkBtn download-btn"
                  >
                    <Download />
                    Download
                  </CSVLink> */}
                </div>
                <Modal
                  show={show}
                  onHide={() => {
                    setShow(false);
                    setPageState(initialPageState);
                    updateState(initialState);
                    setError(false);
                  }}
                  className="add-permit"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {editPermit ? "Edit Permit" : "Add Permit"}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {!pageState?.isModalLoading && (
                      // <div className="action-loader">
                      //   {/* <Spinner /> */}
                      //   Loading...
                      // </div>
                      // :
                      <Form className="row" onSubmit={Submit}>
                        <label>
                          License Plate
                          <span className="mandatory Error">*</span>
                        </label>
                        <Form.Group
                          className="mb-3 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            maxLength={8}
                            placeholder="Enter License Plate"
                            onClick={clearerr}
                            autoFocus
                            name="licensePlate"
                            // defaultValue={editPermit?.licensePlate}
                            Value={state?.licensePlate}
                            onChange={(e) => {
                              updateState({
                                [e.target?.name]: e.target?.value.toUpperCase(),
                              });
                              setError(false);
                            }}
                          />
                          {error && state?.licensePlate <= 0 ? (
                            <div className="Error">
                              Please Enter License Plate.
                            </div>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                        <label
                          className="form-label"
                          onClick={() => setPageState({ isLotModalShow: true })}
                        >
                          Select Lots<span className="mandatory Error">*</span>
                        </label>
                        <Form.Group
                          className="mb-3 col-12 offence-block-permit"
                          controlId="exampleForm.ControlInput1"
                        >
                          <button
                            type="button"
                            placeholder="Mountain View Parking/ParkX.."
                            className="offence-btn  form-control"
                            onClick={() => {
                              setPageState({
                                isLotModalShow: !pageState?.isLotModalShow,
                              });
                              setError(false);
                            }}
                          >
                            {/* <div className="add-offiences"> */}

                            {/* {
                              returnShotLotName(pageState?.selectedLotsName, 20, true, "lotname")
                            } */}

                            {/* </div> */}
                            {pageState?.selectedLotsName?.length > 0 ? (
                              <div className="add-offiences">
                                {returnShotLotName(
                                  pageState?.selectedLotsName,
                                  20,
                                  true,
                                  "lotname"
                                )}
                              </div>
                            ) : (
                              <span className="placeholder-text">
                                Select lots
                              </span>
                            )}

                            <span className="dropdown-arrow"></span>
                          </button>
                          {pageState?.isLotModalShow === true && (
                            <div
                              className="paymentWrapper"
                              onClick={() =>
                                setPageState({ isLotModalShow: false })
                              }
                            >
                              <div
                                className="selectlotblock"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <img
                                  src={close}
                                  onClick={() =>
                                    setPageState({ isLotModalShow: false })
                                  }
                                  className="close-btn"
                                />
                                <ul className="paymentVendorlist">
                                  {pageState?.isLotModalShow === true &&
                                    pageState?.lots?.map((lot, index) => {
                                      const isSelected =
                                        state?.lotId?.indexOf(lot?._id) !== -1;
                                      return (
                                        <li
                                          key={index}
                                          onClick={() => handleOffences(lot)}
                                        >
                                          <div>{lot?.lotname}</div>
                                          <div className="vendor-checkbox">
                                            <input
                                              type="checkbox"
                                              id={`weekday-mon-${index}`}
                                              className="weekday"
                                              checked={isSelected}
                                            />
                                            <label
                                              for={`weekday-mon-${index}`}
                                            ></label>
                                          </div>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            </div>
                          )}
                          {error && state?.lotId <= 0 ? (
                            <div className="Error">
                              Please Select at least one Lot.
                            </div>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                        <label>
                          Permit Type<span className="mandatory Error">*</span>
                        </label>
                        <Form.Group
                          className="mb-3 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          {/* <Form.Control
                            type="text"
                            placeholder="Enter Permit Type"
                            name="permitType"
                            autoFocus
                            Value={state?.permitType}
                            onChange={(e) => updateState({ [e.target?.name]: e.target?.value })}
                          /> */}
                          <Form.Control
                            type="text"
                            // placeholder="Enter Permit Type"
                            placeholder="Ex. staff,owner,member etc..."
                            name="permitType"
                            autoFocus
                            onClick={clearerr}
                            value={state?.permitType}
                            onChange={(e) => {
                              updateState({ [e.target.name]: e.target.value });
                              setError(false);
                            }}
                          />
                          {error && state?.permitType <= 0 ? (
                            <div className="Error">
                              Please Enter Permit Type.
                            </div>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                        <label>
                          Permit Expiration Date
                          {/* <span className="mandatory Error">*</span> */}
                        </label>
                        <Form.Group
                          className="mb-4 col-12 expiration-date"
                          controlId="exampleForm.ControlInput1"
                        >
                          <DatePicker
                            selected={
                              moment()
                                .add(10, "years")
                                ?.isBefore(moment(state?.membershipEndDate))
                                ? null
                                : state?.membershipEndDate
                            }
                            placeholderText="--/--/----"
                            onClick={clearerr}
                            // value={state?.membershipEndDate ?? ""}
                            value={
                              moment()
                                .add(10, "years")
                                ?.isBefore(moment(state?.membershipEndDate))
                                ? null
                                : state?.membershipEndDate
                            }
                            onChange={(date) =>
                              updateState({
                                membershipEndDate: moment(date).tz(tz).toDate(),
                              })
                            }
                            className="date-picker"
                            minDate={moment().add(1, "day").tz(tz).toDate()}
                          />
                          {/* {error && state?.membershipEndDate <= 0 ? (
                            <div className="Error">
                              Please Enter Permit Expiration date
                            </div>
                          ) : (
                            ""
                          )} */}
                        </Form.Group>
                        <Form.Group
                          className="mb-3 col-12 add-permit"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <button type="submit" className="btn DarkBtn">
                            {editPermit ? "Update" : "Submit"}
                          </button>
                        </Form.Group>
                      </Form>
                    )}
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
          {
            <div className="permitlotsWrapper ">
              <div className="table-responsive">
                <table className="table StripTable">
                  <thead>
                    <tr>
                      <th className="text-start">License Plate</th>
                      <th>Lot</th>
                      <th>Permit Type</th>
                      <th>Permit Expiration Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageState?.permits?.length > 0 ? (
                      pageState?.permits?.map((permit, index) => {
                        return (
                          <tr key={permit._id}>
                            <td className="license-plate">
                              <p>{permit?.licensePlate}</p>
                            </td>
                            <td className="permit-lot">
                              {" "}
                              {returnShotLotName(
                                permit?.lotId,
                                20,
                                true,
                                "lotname"
                              )}
                            </td>
                            <td>{permit?.permitType}</td>
                            <td>
                              {moment()
                                .add(10, "years")
                                ?.isBefore(moment(permit?.membershipEndDate))
                                ? "-"
                                : moment(permit?.membershipEndDate)?.format(
                                    "MM/DD/YYYY"
                                  )}
                            </td>
                            <td>
                              <div className="d-flex align-items-center justify-content-center">
                                <button
                                  className="ActionsButtons"
                                  data-bs-toggle="modal"
                                  href="#exampleModalToggle"
                                  role="button"
                                  onClick={() => getPermitbyID(permit?._id)}
                                >
                                  <img src={DarkView} className="DarkImg" />
                                  <img src={WhiteView} className="WhiteImg" />
                                </button>
                                {(permissions?.Permits_update ||
                                  roleTitle === "operator") && (
                                  <button
                                    className="ActionsButtons"
                                    onClick={() => handleEditClick(permit._id)}
                                  >
                                    <img
                                      src={DarkViewEdit}
                                      className="DarkImg"
                                      alt="Edit"
                                    />
                                    <img
                                      src={WhiteViewEdit}
                                      className="WhiteImg"
                                      alt="Edit"
                                    />
                                  </button>
                                )}

                                {(permissions?.Permits_delete ||
                                  roleTitle === "operator") && (
                                  <button
                                    className="ActionsButtons"
                                    onClick={() =>
                                      handleDeleteClick(permit._id)
                                    }
                                  >
                                    <img
                                      src={DarkViewDelete}
                                      className="DarkImg"
                                      alt="Delete"
                                    />
                                    <img
                                      src={WhiteViewDelete}
                                      className="WhiteImg"
                                      alt="Delete"
                                    />
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : pageState?.permits === null ||
                      pageState?.permits?.length === 0 ? (
                      <div className="dataText">
                        <h5>No Result Found.</h5>
                      </div>
                    ) : (
                      searchKey && (
                        <div className="dataText">
                          <h5>No Result Found.</h5>
                        </div>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <div className="pagination-block">
                <Pagination
                  hideOnSinglePage
                  defaultCurrent={pageState?.defaultCurrentPage}
                  onChange={(page) => setPageState({ currentPage: page })}
                  current={pageState?.currentPage}
                  pageSize={pageState?.pageSize}
                  total={pageState?.total}
                  showSizeChanger={false}
                />
              </div>
            </div>
          }
        </div>
      </section>
      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel">
                Permit Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setPageState(initialPageState);
                  updateState(initialState);
                }}
              ></button>
            </div>
            <div className="modal-body">
              {!pageState?.isModalLoading && (
                // <div className="action-loader">
                //   {/* <Spinner /> */}
                //   Loading...
                // </div>
                // :
                <form className="offence-form">
                  <div className="form-group">
                    <label for="text">License Plate</label>
                    <p className="green">{state?.licensePlate}</p>
                  </div>
                  <div className="form-group">
                    <label for="text">Lot Name</label>
                    <div className="lot-name-field">
                      {pageState?.selectedLotsName?.map(
                        ({ lotname }, index) => {
                          return <p key={index}>{lotname}</p>;
                        }
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Permit Type</label>
                    <p>{state?.permitType}</p>
                  </div>
                  <div className="form-group">
                    <label>Permit Expiration Date</label>
                    <p>
                      {moment(state?.membershipEndDate)?.format("MM/DD/YYYY")}
                    </p>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <PermitDeleteModal
        show={showModal}
        onClose={handleCloseModal}
        onSubmit={handleConfirmDelete}
        title="Permit"
      />
    </>
  );
}
