import { connect } from "react-redux";
import { createSchedule, getSchedule } from "../../store/home/duck";
import LotSchedule from "./LotSchedule";

export default connect(
  (state) => ({ }),
  {
    createSchedule,
    getSchedule
  }
)(LotSchedule);
