import { connect } from "react-redux";
import { getEquipmentCharges, refundEquipment } from "../../../store/home/duck";
import EquipmentFeeComponent from "./Component";

const EquipmentFeeContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    getEquipmentCharges,
    refundEquipment
  }
)(EquipmentFeeComponent);

export default EquipmentFeeContainer;
