
// import React from 'react'
import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import IcClose from "../../images/ic-close.svg";
import Delete from "../../images/delete-popup.svg";
import close from "../../../src/images/Close.svg"
import './modal.css'
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/actioncreator';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Refund_modal = ({ onClose, setReason, reason, show, onSubmit,refund_role, title, header_title, amount, maximumRefundAmount, fullRefundAmount, taggrFee, role,setrefund_role, setTaggrFee,operator_name, setAmount, ...rest }) => {
  const [refundType, setRefundType] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null)
  useEffect(() => {
    setRefundType(rest?.refund_type ? rest?.refund_type : "full")
    setInfoMessage(
      (header_title === "Refund" || header_title === "Request Refund") 
    ? (
      maximumRefundAmount > 0 ?
      <span style={{fontSize: "12px"}}>
        You can issue a <strong className='text-danger'>full refund</strong> or a maximum of <strong className='text-danger'>${maximumRefundAmount}</strong> for a partial refund.
      </span>
      :
      <span style={{fontSize: "12px"}}>
        You can only issue a <strong className='text-danger'>full refund.</strong>
      </span>
    )
    : null)
  }, [show])
  // const [refund_role, setrefund_role] = useState();
  const [activeButton, setActiveButton] = useState(null);
  const [displayCard, setDisplayCard] = useState(false)

  
  const dispatch = useDispatch()
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe && !elements) {
    console.log("stripe script didn't loaded!")
    return
  }

  const clearCardElementData = () => elements.getElement(CardElement)?.clear()

  const onVoidSubmit = async () => {
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    if(!error){
      clearCardElementData()
      const { id } = paymentMethod
      setDisplayCard(false)
      onSubmit(id)
    }
  }

  const handleOnSubmit = () => {
    if(refundType === "partial" && Number(amount) > Number(maximumRefundAmount) || amount < 1 ){
      dispatch(setAlert("error", "Invalid amount entered!"));
      return
    }
    onSubmit()
  } 

  const WithholdTaggrCheckbox = ({checkBoxLabel, taggrFee, setTaggrFee}) => (
    <div className="mt-3 text-start">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          checked={taggrFee}
          onChange={() => {
            setTaggrFee(!taggrFee);
          }}
        />
        <label className="form-check-label" for="flexCheckDefault">
          {checkBoxLabel}
        </label>
      </div>
    </div>
  );

  return (
    <div>
      <div className={`modalContainer ${show ? "show" : ""}`} 
        onClick={() => {
          clearCardElementData()
          setDisplayCard(false)
          onClose()
        }}>
        <div className="modalContent" onClick={(e) => e.stopPropagation()}>
          <div className="modaHeader">
            <h5 className="text-center">{header_title} Notice</h5>
            <button onClick={() => {
                clearCardElementData()
                setDisplayCard(false)
                onClose()
              }} className='RefundCloseHeader'><img src={close} /></button>
          </div>
          <div className="modalBody">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h5>Notice number:</h5>
              <p className="m-0">#{title}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h5>Amount:</h5>
              {(header_title === "Refund" && maximumRefundAmount > 0 && refundType !== "full" ) 
              || (header_title === "Request Refund" && maximumRefundAmount > 0)
              ?
              <input 
              className='m-0'
              type='text'
              value={amount}
              onChange={(e) => {
                const enteredValue = e.target.value;
                const isValid = /^-?\d*\.?\d*$/.test(enteredValue);
                if (isValid) {
                  setAmount(enteredValue);
                }
              }}
              />
            : <p>${amount}</p>
            }
            </div>
            {infoMessage && <p>ℹ️ {infoMessage}</p>}
            <RefundTypeComponent 
              header_title={header_title}
              role={role}
              refundType={refundType}
              setRefundType={setRefundType}
              setrefund_role={setrefund_role}
              setAmount={setAmount}
              maximumRefundAmount={maximumRefundAmount}
              fullRefundAmount={fullRefundAmount}
              setTaggrFee={setTaggrFee}
            />
            {(header_title !== 'Void' && header_title !== 'Reduce' && header_title !== 'Deny' && role !== 'operator') 
            && refundType === "full"
            && (
              <div className="mb-4">
                <h5 className="text-start">Issued by:</h5>
                <div className="d-flex MargMinus">
                  <button
                  //  className="btn PrimaryBtn Outline"
                  className={`btn PrimaryBtn Outline ${refund_role === "operator" ? "active_role" : ""}`}
                    onClick={() => {
                      setrefund_role("operator");
                      console.log(refund_role,"setrefund_role-inside modal code---->>>");
                    }}
                  >
                    {operator_name}
                  </button>
                  <button 
                  // className="btn PrimaryBtn Outline"
                  className={`btn PrimaryBtn Outline ${refund_role === "admin" ? "active_role" : ""}`}
                    onClick={() => {
                      setrefund_role("admin");
                      console.log(refund_role,"setrefund_role-inside modal code---->>>");
                    }}
                  >
                    Taggr solutions
                  </button>
                </div>
                <WithholdTaggrCheckbox 
                  checkBoxLabel="Withhold Taggr's Fees"
                  taggrFee={taggrFee}
                  setTaggrFee={setTaggrFee}
                />
              </div>
            )}
            {header_title === 'Void' && rest?.citation_type === "tag_vehicle" && 
             <div className="d-flex mb-4">
              <button className={`btn border`} onClick={() => setDisplayCard(!displayCard)}>
              {displayCard ? 'Remove Card': 'Add Card +'}
             </button>
            </div>
            }
            {header_title === 'Void' && rest?.citation_type === "tag_vehicle" && displayCard &&
             <CardElement options={{
               hidePostalCode: true,
              }}/>
            }
            {
              header_title === "Void" && 
              rest?.citation_type === "mail_in" &&
              <div className='mb-3'>
                <WithholdTaggrCheckbox 
                  checkBoxLabel="Withhold 1099 amount"
                  taggrFee={taggrFee}
                  setTaggrFee={setTaggrFee}
                  />
              </div>
            }
            <div className={`d-flex justify-content-between align-items-center ${header_title === 'Void' && rest?.citation_type === "tag_vehicle" ? 'mt-4 mb-4': "mb-2"}`}>
              <h5>Reason:</h5>
              <p className="m-0"></p>
            </div>
            <div className="TextareaWrapper">
              {/* <p className='m-0 text-start'>Damaged item received, partial refund issued</p> */}
              <textarea onChange={setReason} value={reason} />
            </div>
          </div>
          <div className="modalFooter" style={{margin: 0}}>
            <button className="btn PrimaryBtn" onClick={() => header_title === "Void" && displayCard ? onVoidSubmit() : handleOnSubmit() }>
              Confirm
            </button>
            {/* <button className="btn PrimaryBtn" onClick={onClose}>
              Cancel
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function (props) {
  return (
    <Elements stripe={stripePromise}>
      <Refund_modal {...props}/>
    </Elements>
  )
}


function RefundTypeComponent({header_title, role, refundType, setRefundType, setrefund_role, setTaggrFee, setAmount, maximumRefundAmount, fullRefundAmount}){
  return (
    <>
      {(header_title !== 'Void' && header_title !== 'Reduce' && header_title !== 'Deny' && role !== 'operator') 
      && (
        <div className="mb-4">
          {/* <h6 className="text-start">Responsibility of:</h6> */}
          <div className="d-flex MargMinus">
            <button
            className={`btn PrimaryBtn Outline ${refundType === "partial" ? "active_role" : ""}`}
              onClick={() => {
                setrefund_role("admin");
                setAmount(maximumRefundAmount)
                setRefundType("partial")
                setTaggrFee(false)
              }}
            >
              Partial Refund
            </button>
            <button
            className={`btn PrimaryBtn Outline ${refundType === "full" ? "active_role" : ""}`}
              onClick={() => {
                setrefund_role("admin");
                setAmount(fullRefundAmount)
                setRefundType("full")
              }}
            >
              Full Refund
            </button>
          </div>
        </div>
      )}
    </>
  )
}