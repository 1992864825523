import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import OperatorHeader from '../../components/common/Header/container';
import leftArrow from "../../images/left-arrow.svg";
import { Grid, Button, TextField } from '@mui/material';
import { setAlert, setLoading } from "../../store/actioncreator";
import { useNavigate, useParams } from "react-router-dom";
import * as moment from "moment-timezone"
import useIsMobile from "../../hooks/useIsMobile";

export default function LotScheduleComponent(props) {
  const { id } = useParams()
  const isMobile = useIsMobile();
  const timezone = moment.tz.guess()
  console.log(timezone, "<==timezone")
  const navigate = useNavigate()
  const [schedules, setSchedules] = useState([{ startDatetime: '', endDatetime: '' }]);
  const dispatch = useDispatch();

  console.log(schedules, "<==schedules")
  const convertToLocal = (datetime) => {
    return datetime ? moment(datetime).tz(timezone).format("YYYY-MM-DDTHH:mm") : "";
  };

  useEffect(() => {
    const fetchSchedules = async () => {
      dispatch(setLoading(true));
      try {
        const { value } = await props?.getSchedule(id)
        if (value?.success) {
          setSchedules(
            value?.schedules?.length > 0 ?
            value?.schedules?.map(schedule => 
              ({startDatetime: convertToLocal(schedule?.startDate), endDatetime: convertToLocal(schedule?.endDate)})) 
            : [{ startDatetime: "", endDatetime: "" }]
          );
        } else {
          dispatch(setAlert("error", value?.message));
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message));
      }
      dispatch(setLoading(false));
    };
    fetchSchedules();
  }, [id, dispatch, props]);

  const handleAddRow = () => {
    setSchedules([...schedules, { startDatetime: '', endDatetime: '' }]);
  };

  const handleChange = (index, field, value) => {
    const updatedSchedules = schedules.map((schedule, i) =>
      i === index ? { ...schedule, [field]: value } : schedule
    );
    setSchedules(updatedSchedules);
  };

  const handleSubmit = async () => {
    const invalidSchedules = schedules?.some(
      (schedule) =>
        (schedule?.startDatetime && !schedule?.endDatetime) || (!schedule?.startDatetime && schedule?.endDatetime)
    );
  
    if (invalidSchedules) {
      dispatch(setAlert("info", "Both start and end date must be filled."));
      return;
    }
    dispatch(setLoading(true));
    try {
      const { value } = await props?.createSchedule(id, schedules, timezone)
      if (value?.success) {
        navigate("/lots");
        dispatch(setAlert("success", value?.message))
      }
      else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
      console.log(error.message, "<<<user error");
    }
    dispatch(setLoading(false));
  };

  return (
    <section className="mainWrapper">
      <OperatorHeader />
      <div className="main-wrapper paddzero" id="profile-wrapper">
        <div className="HeadingSection addlote-title">
          <img src={leftArrow} className="left-arrow" onClick={() => navigate(-1)} alt="Go Back" />
          <h4>Lot Scheduling</h4>
        </div>
        <Grid container spacing={2} sx={{
            width: isMobile ? 'calc(100% - 32px)' : '90%' 
          }}
          mx={2} my={2}>
          {schedules.map((schedule, index) => (
            <Grid container item spacing={2} alignItems="center" key={index}>
              <Grid item xs={12} sm={5}>
                <TextField
                  label="Start Date & Time"
                  type="datetime-local"
                  fullWidth
                  value={schedule.startDatetime}
                  onChange={(e) => handleChange(index, 'startDatetime', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputProps: {
                      min: moment()?.tz(timezone).format("YYYY-MM-DDTHH:mm"), // Disable past dates
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  label="End Date & Time"
                  type="datetime-local"
                  fullWidth
                  value={schedule.endDatetime}
                  onChange={(e) => handleChange(index, 'endDatetime', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputProps: {
                      min: moment()?.tz(timezone).format("YYYY-MM-DDTHH:mm"), // Disable past dates
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() =>
                    setSchedules(schedules.filter((_, i) => i !== index))
                  }
                  disabled={schedules.length === 1}
                  fullWidth
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button variant="outlined" color="primary" onClick={handleAddRow} fullWidth={isMobile}>
              Add More
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth={isMobile}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </div>
    </section>
  );
}